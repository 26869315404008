.my-labels {
  font-size: 10px; /* adjust as needed */
  color: black; /* adjust as needed */
}

.leaflet-tooltip.my-labels {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1px;
  border: none;
  box-shadow: none;
  top: -10px !important; /* adjust as needed */
}
